import React from 'react'
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { H1, H2, Paragraph, PageWrapper, DefinitionList, Btn1satsu, BtnBookCellar } from '../style/common/common'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
// import bookcellar from '../../icon-bookcellar.svg'

const HowToOrderPage = ({ data, pageContext, location }) => {
  const { breadcrumb: { crumbs } } = pageContext
  const title = "お取引について"

  return (
    <Layout
      location={location}
    >
      <SEO
        title={`${title} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={title}
      />
      <PageWrapper>
        <H1>{title}</H1>
        <H2>取次取引</H2>
        <Paragraph>
          ☞<a href="http://www.kuwatani.co.jp/" target="_blank" rel="noopener noreferrer">鍬谷書店</a>経由で日販やトーハンなどすべての帳合取次に納本が可能です。<br />☞返品は無期限で承りますので、長期にわたって販売いただけますようお願いいたします。
        </Paragraph>
        <H2>直取引</H2>
        <DefinitionList>
          <dt>条件</dt>
          <dd>買切</dd>
          <dt>注文ロット</dt>
          <dd>2冊より（6冊以下は偶数冊にしていただけると助かります）</dd>
          <dt>掛率</dt>
          <dd>70%（20冊以上の場合は65%）</dd>
          <dt>送料</dt>
          <dd>元払い</dd>
          <dt>支払</dt>
          <dd>月末締め翌月末精算（お振込手数料はご負担ください）</dd>
        </DefinitionList>
        <Paragraph>☞その他、委託条件でのお取引やイベント時の販売につきましては別途お気軽にご相談ください。</Paragraph>
        <DefinitionList>
          <dt>メール</dt>
          <dd><Link to="/contact">こちら</Link>からお問い合わせください。</dd>
          <dt>TEL</dt>
          <dd>0422-77-4020</dd>
        </DefinitionList>
        <Btn1satsu>
          <a href="https://1satsu.jp/publisher/244/" target="_blank" rel="noopener noreferrer">
            <span>弊社の本はここでも取引できます</span>
            <span><img src={`../../icons/icon-1satsu.svg`} alt="1冊！ 取引所"/></span>
          </a>
        </Btn1satsu>
        <BtnBookCellar>
          <a href="https://www.bookcellar.jp/publishertop/list/387" target="_blank" rel="noopener noreferrer">
            <span>ウェブでかんたん発注</span>
            <span><img src={`../../icons/icon-bookcellar.svg`} alt="bookcellar"/></span>
          </a>
        </BtnBookCellar>
        {/* <Link to="/">Go back to the homepage</Link> */}
      </PageWrapper>
    </Layout>
  )
}

export default HowToOrderPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
